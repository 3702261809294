<template>
  <div class="kt-widget20">
    <div class="kt-widget20__chart" style="height: 330px">
      <Chart1 ref="chart" :options="chartOptions"></Chart1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Chart from 'chart.js';
import Chart1 from '@/views/partials/widgets/Chart1.vue';

export default {
  name: 'stacked-bar-chart',
  components: {
    Chart1
  },
  data() {
    return {
      chartOptions: {}
    };
  },
  props: {
    title: [String, Number],
    desc: [String, Number, Array],
    variant: [String, Array],
    label: Array,
    data: Array
  },
  mounted() {},
  computed: {
    ...mapGetters(['layoutConfig'])
  },
  watch: {
    data: function (val) {
      // const ctx = this.$refs["chart"].$el.getContext("2d");
      // if (this.variant == "primary") chartColor = "#e1f0ff";
      // else if (this.variant == "warning") chartColor = "#fff4de";
      // else if (this.variant == "danger") chartColor = "#ffe2e5";

      const defaults = {
        type: 'bar',
        data: {
          labels: this.label,
          datasets: this.desc.map((value, i) => {
            let chartColor = this.variant[i].includes('#')
              ? this.variant[i]
              : this.layoutConfig(`colors.state.${this.variant[i]}`);
            return {
              label: this.desc[i],
              backgroundColor: chartColor,
              borderColor: chartColor,
              pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
              pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
              pointHoverBackgroundColor: this.layoutConfig('colors.state.info'),
              pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),
              data: JSON.parse(JSON.stringify(val[i])),
              lineTension: 0
            };
          })
        },
        options: {
          legend: {
            display: true,
            position: 'top'
          },
          title: {
            display: true,
            text: this.title
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, data) {
                const total = data.datasets.reduce((a, b) => a + b.data[tooltipItem.index], 0) || 0;
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += parseFloat(tooltipItem.value || 0).toLocaleString();
                label += ` | ${parseFloat(((tooltipItem.value || 0) / total) * 100).toFixed(2) || 0}%`;
                return label;
              }
            }
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                },
                stacked: true
              }
            ],
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                },
                stacked: true
              }
            ]
          },
          layout: {
            padding: {
              left: -10,
              right: 0,
              top: 0,
              bottom: -10
            }
          }
        }
      };
      this.chartOptions = { ...defaults };
    }
  }
};
</script>
