<template>
  <div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-4 mb-4">
      <b-button-group class="w-100" size="sm">
        <b-button
          v-for="key in dayNumList"
          :key="'num_' + key"
          :variant="isActive(key) ? 'success' : 'outline-success'"
          @click="chooseDateFilter(key)"
          >{{ key }} days</b-button
        >
      </b-button-group>
    </div>
    <div class="col-md-2 mb-4">
      <b-button variant="primary" size="sm" class="w-100" @click="exportUser"
        ><i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export</b-button
      >
    </div>
    <div :class="`col-md-${this.user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 style="color: #db4437">Google Ads</h5>
              <h1 style="color: #db4437">{{ total.google }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div :class="`col-md-${this.user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 style="color: #3b5998">Facebook Ads</h5>
              <h1 style="color: #3b5998">{{ total.facebook }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div :class="`col-md-${this.user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 class="text-success">SMS</h5>
              <h1 class="text-success">{{ total.sms }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <!-- <div class="col-md-3 col-sm-6" v-if="this.user.role !== 'athena'">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 class="text-info">Offline</h5>
              <h1 class="text-info">{{ total.offline }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div> -->
    <div class="col-md-3 col-sm-6" v-if="this.user.role !== 'athena'">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 class="text-warning">App</h5>
              <h1 class="text-warning">{{ total.app }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Users By Platform</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.platform.stackDesc"
            :variant="barCharts.platform.color"
            :label="barCharts.platform.stackLabel"
            :data="barCharts.platform.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Users By Channel</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.channel.stackDesc"
            :variant="barCharts.channel.color"
            :label="barCharts.channel.stackLabel"
            :data="barCharts.channel.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <!-- <div class="col-md-6 col-sm-12" v-if="this.user.role !== 'athena'">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Users Offline</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.offline.stackDesc"
            :variant="barCharts.offline.color"
            :label="barCharts.offline.stackLabel"
            :data="barCharts.offline.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div> -->
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users By Platform</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_platform"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users By Channel</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_channel"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <!-- <div class="col-md-6 col-sm-12" v-if="this.user.role !== 'athena'">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users Offline</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_offline"></PieChart>
        </template>
      </KTPortlet>
    </div> -->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import KTPortlet from '@/views/partials/content/Portlet.vue';
import StackedBarChart from '@/views/partials/widgets/StackedBarChart.vue';
import PieChart from '@/views/partials/widgets/PieChart.vue';
import ApiService from '@/common/api.service.js';
import { mapGetters } from 'vuex';
import { exportRequest } from '@/utils/download.js';
export default {
  name: 'marketing_statistics',
  components: {
    KTPortlet,
    StackedBarChart,
    PieChart
  },
  data() {
    return {
      totalUser: 10,
      barChartList: ['platform', 'channel'],
      pieChartList: ['total_platform', 'total_channel'],
      from: null,
      to: null,
      barCharts: {
        platform: {
          stackLabel: [],
          stackData: [],
          stackDesc: [],
          color: []
        },
        channel: {
          stackLabel: [],
          stackData: [],
          stackDesc: [],
          color: []
        },
        offline: {
          stackLabel: [],
          stackData: [],
          stackDesc: [],
          color: []
        }
      },
      pieCharts: {
        total_platform: [],
        total_channel: [],
        total_offline: []
      },
      total: {
        google: 0,
        facebook: 0,
        sms: 0,
        // offline: 0
        app: 0
      },
      filterDateNum: null,
      dayNumList: [7, 15, 30],
      debounceTimer: null
    };
  },
  created() {
    if (this.user.role !== 'athena') {
      this.barChartList = [...this.barChartList, 'offline'];
      this.pieChartList = [...this.pieChartList, 'total_offline'];
    }
    this.chooseDateFilter(30);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Statistics',
        route: 'marketing-statistics'
      },
      { title: 'Marketing Statistics' }
    ]);
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  methods: {
    chooseDateFilter(key) {
      if (this.filterDateNum == key) return;
      this.filterDateNum = key;
      let { from, to } = this.getFromTo(new Date(), key);
      this.to = to;
      this.from = from;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        await this.getAllData();
      }, 1000);
    },
    getFromTo(date, num) {
      let from, to;
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      to = new Date(date).toISOString();
      date.setDate(date.getDate() - num);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      from = new Date(date).toISOString();
      return { from, to };
    },
    isActive(val) {
      return this.filterDateNum == val;
    },
    async getAllData() {
      let getBarChartDataList = this.barChartList.map(async (chart) => {
        let res = await this.getData(chart, this.from, this.to);
        return res;
      });
      let getPieChartDataList = this.pieChartList.map(async (chart) => {
        let res = await this.getData(chart, this.from, this.to);
        return res;
      });
      const barChartsData = await Promise.all(getBarChartDataList);
      const pieChartsData = await Promise.all(getPieChartDataList);
      const colorArr = [
        '#ffb822',
        '#5867dd',
        '#FF7B89',
        '#CBE54E',
        '#5d78ff',
        '#fd397a',
        '#e8ecfa',
        '#8950fc',
        '#0abb87'
      ];
      let chartData = {};
      barChartsData.forEach((chart) => {
        let data = {
          stackLabel: [],
          stackData: [],
          stackDesc: [],
          color: []
        };
        Object.values(chart.data).forEach((val) => {
          // console.log(chart.data[key]);
          Object.keys(val).forEach((desc) => {
            if (!data.stackDesc.includes(desc)) data.stackDesc = [...data.stackDesc, desc];
          });
        });
        data.stackData = new Array(data.stackDesc.length).fill([]);
        Object.keys(chart.data).forEach((key) => {
          data.stackLabel.push(key);
          data.stackDesc.forEach((desc, i) => {
            data.stackData[i] = [...data.stackData[i], chart.data[key][desc] || 0];
          });
        });
        let i = 0;
        data.color = data.stackDesc.map((desc) => {
          if (desc.toLowerCase().includes('google')) return '#DB4437';
          else if (desc.toLowerCase().includes('facebook')) return '#3b5998';
          else if (desc.toLowerCase().includes('sms')) return '#0abb87';
          else if (desc.toLowerCase().includes('app') && !desc.toLowerCase().includes('-')) return '#ffb822';
          // else if (desc.toLowerCase().includes('offline')) return '#8950fc';
          else {
            return colorArr[i++];
          }
        });
        chartData[chart.type] = { ...data };
      });
      this.barCharts = { ...chartData };
      let pieData = {};
      pieChartsData.forEach((chart) => {
        let data = [];
        let i = 0;
        Object.keys(chart.data).forEach((key) => {
          let color = '';
          if (key.toLowerCase().includes('google')) {
            color = '#DB4437';
            if (chart.type == 'total_channel') this.total = { ...this.total, google: chart.data[key] };
          } else if (key.toLowerCase().includes('facebook')) {
            color = '#3b5998';
            if (chart.type == 'total_channel') this.total = { ...this.total, facebook: chart.data[key] };
          } else if (key.toLowerCase().includes('sms')) {
            color = '#0abb87';
            if (chart.type == 'total_channel') this.total = { ...this.total, sms: chart.data[key] };
          } else if (key.toLowerCase().includes('app')) {
            // color = '#8950fc';
            color = key.toLowerCase().includes('-') ? colorArr[i++] : '#ffb822';
            if (chart.type == 'total_channel') this.total = { ...this.total, app: chart.data[key] };
            // } else if (key.toLowerCase().includes('offline')) {
            //   color = '#8950fc';
            //   if (chart.type == 'total_channel') this.total = { ...this.total, offline: chart.data[key] };
          } else color = colorArr[i++];
          data.push({
            label: key,
            value: chart.data[key],
            color
          });
        });
        pieData[chart.type] = [...data];
      });
      this.pieCharts = { ...pieData };
    },
    getData(type = 'platform', from, to) {
      return new Promise((resolve) => {
        if (!from) {
          from = new Date();
          from = new Date(from.setDate(from.getDate() - 30)).toISOString();
        }
        if (!to) to = new Date().toISOString();
        ApiService.get('statistics/accounts', `?type=${type}&from=${from}&to=${to}`)
          .then((resp) => {
            resolve({ data: resp.data.data.data, type });
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            resolve(false);
          });
      });
    },
    exportUser() {
      exportRequest(`/exports/customers?from=${this.from}&to=${this.to}`);
    }
  }
};
</script>
